import { useCallback } from 'react'

import { CompetitionCard } from '@components/ui/molecules'
import { Title } from '@components/ui/atoms'

import { Box } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'

import { ICompetitionTilesGrid } from './CompetitionTilesGrid.types'
import { StyledTilesContainer } from './CompetitionTilesGrid.styled'

const CompetitionTilesGrid = (props: ICompetitionTilesGrid) => {
  const {
    id,
    variant = 'h2',
    heading = null,
    competitions = [],
    isPast = false,
    analytics = null
  } = props

  const renderImageItem = useCallback(() => {
    return competitions.map(competition => {
      return (
        <Grid2 xs={6} sm={6} md={4} lg={4} key={competition.id}>
          <CompetitionCard
            layoutMode="responsive"
            competition={competition}
            isPast={isPast}
            analytics={analytics}
          />
        </Grid2>
      )
    })
  }, [competitions, isPast, analytics])

  return (
    <StyledTilesContainer id={id} spacing={2}>
      {heading && <Title variant={variant}>{heading}</Title>}

      <Box>
        <Grid2 container spacing={2}>
          {renderImageItem()}
        </Grid2>
      </Box>
    </StyledTilesContainer>
  )
}

export default CompetitionTilesGrid
